import cloneDeep from 'lodash/cloneDeep';
import forEach from 'lodash/forEach';
import get from 'lodash/get';

import {
  SUMMARY_CALCULATE_INVOICE_SUCCESS,
  SUMMARY_GET_NEW_CABIN_ERROR,
  SUMMARY_GET_NEW_CABIN_SUCCESS,
} from '@/constants/actionTypes';
import { cabinHoldAction, cabinUnholdAction } from '@/ducks/cabinHold/actions';
import { getSearchParams } from '@/ducks/routes/history';
import { selectSailingData } from '@/ducks/voyageInfo/sailing/selectors';
import { getNewCabinByAttributes } from '@/helpers/api/app';
import { getUnholdBody, mapNewCabinToCalculateInvoice } from '@/helpers/data/mappers/Summary';
import { getBookingSource } from '@/helpers/util';

export const getNewCabinByAttributesAction =
  (zone, voyageSearchFilters, calculateInvoice) => async (dispatch, getState) => {
    let data = {};
    const { currencyCode } = getSearchParams();
    try {
      data = await getNewCabinByAttributes(zone, voyageSearchFilters);
      dispatch({
        payload: data,
        type: SUMMARY_GET_NEW_CABIN_SUCCESS,
      });

      // Make unhold request for previous cabin
      const sailingData = selectSailingData(getState()) || {};
      forEach(get(calculateInvoice, 'cabinInvoices'), (cabinInvoice) => {
        dispatch(cabinUnholdAction(getUnholdBody(cabinInvoice, sailingData)));
      });

      forEach(get(data, 'cabins', []), (cabin) => {
        const cabinDetails = {
          cabinDetails: [
            {
              cabinCategoryCode: get(cabin, 'cabinCategoryCode'),
              cabinNumber: get(cabin, 'cabinNumber'),
            },
          ],
          voyageId: get(voyageSearchFilters, 'voyageId'),
          ...getBookingSource(currencyCode || 'USD', false, false),
        };
        dispatch(cabinHoldAction(cabinDetails));
      });

      const newCalculateInvoice = mapNewCabinToCalculateInvoice(cloneDeep(calculateInvoice), data, zone);
      dispatch({
        payload: newCalculateInvoice,
        type: SUMMARY_CALCULATE_INVOICE_SUCCESS,
      });
    } catch (err) {
      dispatch({
        payload: null,
        type: SUMMARY_GET_NEW_CABIN_ERROR,
      });
    }

    return data;
  };

export default getNewCabinByAttributesAction;
