import { api } from '@/helpers/api/core';
import sharedCall from '@/helpers/sharedCall';
import { type TISODate } from '@/infra/types/common';
import { type TUserProfile, type TUserSubscriptionSource } from '@/infra/types/user';

export type TSubscribeArgs = Partial<TUserSubscriptionSource> & {
  dateOfBirth: TISODate;
  email: string;
  firstName: string;
  isOptInForEmail: boolean;
  isOptInForSMS: boolean;
  lastName: string;
  notificationTypeCode: string;
  phoneCountryCode: `${number}`;
  phoneNumber: `${number}`;
};

export const subscribe = (payload: TSubscribeArgs) => api.post('/subscribe', payload);

export const userProfile = sharedCall((token: string): Promise<TUserProfile> => {
  if (!token) return Promise.reject('Access token required');
  return api.get('/userprofile', {
    headers: {
      Authorization: `bearer ${token}`,
    },
  });
});
