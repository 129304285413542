import { gql } from '@apollo/client';

export const FETCH_VOYAGE_CREDITS = gql`
  query GetVoyageCredits($clientId: String!, $currency: String!, $voyageStartDate: String!) {
    voyageCredits(clientId: $clientId, currency: $currency, voyageStartDate: $voyageStartDate) {
      amount
      amountLeft
      currencyCode
      effectiveDate
      expireDate
      eligibleFrom
      eligibleTo
      valueType
      type
      identifier
      comments
    }
  }
`;
