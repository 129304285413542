import { HttpError } from '@/helpers/api/utils';
import { type TOptional } from '@/types/common';

export type TCaught = {
  error: HttpError;
  isCaught: boolean;
};

const withApiErrorCatcher =
  <A extends unknown[], R, C extends unknown[]>(
    apiCall: (...args: A) => Promise<R>,
    errorCatcher: (error: HttpError, ...args: C) => Promise<TOptional<boolean>>,
    ...catcherArgs: C
  ) =>
  async (...apiArgs: A): Promise<R | TCaught> => {
    try {
      return await apiCall(...apiArgs);
    } catch (err) {
      if (err instanceof HttpError && (await errorCatcher(err, ...catcherArgs))) return { error: err, isCaught: true };
      throw err;
    }
  };

export default withApiErrorCatcher;
