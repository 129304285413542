import vwoSDK from 'vwo-node-sdk';

import { VWO_MAIN_ACCOUNT_ID, VWO_SETTINGS_FILE_STORAGE_KEY } from '@/constants/vwoConstants';
import { env } from '@/environment';

import { getSessionStorageValue, setSessionStorageValue } from '../util/storage';

let instance: null | vwoSDK.vwoInstance = null;

export default async function getInstance() {
  if (instance) {
    return instance;
  }

  let settingsFile = getSessionStorageValue(VWO_SETTINGS_FILE_STORAGE_KEY);

  if (!settingsFile) {
    settingsFile = await vwoSDK.getSettingsFile(VWO_MAIN_ACCOUNT_ID, env.VWO_SDK_KEY);

    setSessionStorageValue(VWO_SETTINGS_FILE_STORAGE_KEY, settingsFile);
  }

  if (!instance) {
    instance = vwoSDK.launch({ settingsFile });
  }

  return instance;
}
