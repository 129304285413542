import type { TCabinHoldApiCabinDetail, TCabinInvoice } from '@/infra/types/cabin';
import type { TOptional } from '@/types/common';

import { buildCabinFullNumber } from '@/helpers/data/mappers/Cabin';

export type TCabinInvoiceSlice = Pick<
  TCabinInvoice,
  'cabinNumber' | 'cabinSide' | 'cabinTypeCode' | 'deck' | 'guestCount'
>;

const makeHoldCabinDetailArg = (cabinInvoice: TCabinInvoiceSlice): TOptional<TCabinHoldApiCabinDetail> => {
  const { cabinTypeCode: cabinCategoryCode, guestCount: maxOccupancy } = cabinInvoice || {};
  if (cabinCategoryCode && maxOccupancy) {
    const cabinNumber = buildCabinFullNumber(cabinInvoice);
    if (cabinNumber) return { cabinCategoryCode, cabinNumber, maxOccupancy };
  }
};

export default makeHoldCabinDetailArg;
