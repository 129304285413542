import { CONFIRMATION_TODO_LIST_FAILURE, CONFIRMATION_TODO_LIST_SUCCESS } from '@/constants/actionTypes';
import { fetchConfirmationTodoList } from '@/helpers/api/app';

export function fetchConfirmationTodoListAction(reservationId, isVip, clientTransactionId) {
  return async (dispatch) => {
    try {
      const data = await fetchConfirmationTodoList(reservationId, isVip, clientTransactionId);
      dispatch({
        payload: data,
        type: CONFIRMATION_TODO_LIST_SUCCESS,
      });
    } catch (err) {
      dispatch({
        type: CONFIRMATION_TODO_LIST_FAILURE,
      });
    }
  };
}
