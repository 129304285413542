import { PAYMENT_SET_PAYMENT_OPTION } from '@/constants/actionTypes';

export const setPaymentOptionAction = (paymentOption) => (dispatch) => {
  dispatch({
    payload: paymentOption,
    type: PAYMENT_SET_PAYMENT_OPTION,
  });
};

export default setPaymentOptionAction;
