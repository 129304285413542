export type TApiErrorData = {
  code: string;
  message: string;
};

export const enum BookNowFailureCode {
  CABIN_EXHAUSTED = '0113323',
  GENERAL = '0111322',
}

export const enum CalculateInvoiceFailureCode {
  SEAWEAR_SAILOR_NOT_PERMITTED = '013323',
}
