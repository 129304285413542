import { ChooseCabinSubMetaSearchParams, CommonSearchParams } from '@/ducks/routes';
import { navigateToSummary } from '@/ducks/routes/navigation';
import { setSelectedCabin, setSelectedCabinError } from '@/ducks/voyageInfo/cabin';
import { setPaymentBookNowCallStatus } from '@/helpers/managers/payment';

export const onChooseCabinAction = (cabinType, metaData, isUpsellApplied) => (dispatch) => {
  try {
    dispatch(setSelectedCabin({ cabin: metaData }));
    setPaymentBookNowCallStatus(false);
  } catch (err) {
    dispatch(setSelectedCabinError({ error: err }));
  }

  navigateToSummary({
    apply: {
      [ChooseCabinSubMetaSearchParams.cabinCategoryCode]: metaData?.cabinCategoryCode,
      [ChooseCabinSubMetaSearchParams.cabinType]: cabinType,
      ...(isUpsellApplied !== undefined ? { [ChooseCabinSubMetaSearchParams.isUpsell]: isUpsellApplied } : {}),
    },
    remove: [CommonSearchParams.showError],
  });
};

export default onChooseCabinAction;
