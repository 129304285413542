import { useContext } from 'react';

import MobileDeviceContext from '../contexts/MobileDeviceContext';

export const useIsMobile = () => {
  return useContext(MobileDeviceContext);
};

export const useIsDesktop = () => {
  return !useContext(MobileDeviceContext);
};
