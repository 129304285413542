import isEmpty from 'lodash/isEmpty';

import { SAILOR_DETAILS_BOOK_NOW_ERROR, SAILOR_DETAILS_BOOK_NOW_SUCCESS } from '@/constants/actionTypes';
import { getAccessKey } from '@/ducks/accessKeys';
import { bookNow } from '@/helpers/api/app';
import buildUpdateReservationBody from '@/helpers/api/mappers/req/bookNow/buildUpdateReservationBody';
import { getCabinNumber } from '@/helpers/selectors';
import { getSessionStorageValue, setSessionStorageValue } from '@/helpers/util';

import sailorFormUpdateAction from '../../sailorDetails/sailorFormUpdate';

export const updateReservationForSailorDetailsAction =
  (bookNowPaymentData, sailorParams) => async (dispatch, getState) => {
    try {
      const state = getState();
      const cabinNoInsession = getSessionStorageValue('cabinNumber');
      const cabinNumber = getCabinNumber(state);
      if (isEmpty(cabinNoInsession) && cabinNumber) {
        setSessionStorageValue('cabinNumber', cabinNumber);
      }
      const { lookup, parsedParameters, payInFullPromo, sailingData } = sailorParams;
      const accessKeyDetails = getAccessKey(state);

      if (accessKeyDetails?.accessKey && accessKeyDetails?.promoCode) {
        parsedParameters.accessKey = accessKeyDetails.accessKey;
        parsedParameters.promoCode = accessKeyDetails.promoCode;
      }
      if (isEmpty(parsedParameters?.shipCode)) {
        parsedParameters.shipCode = sailingData?.ship?.code;
      }

      const sailorObj = {
        ...bookNowPaymentData?.sailorDetails,
        cabinNumber: cabinNumber || cabinNoInsession,
        lookup,
        parsedParameters,
        payInFullPromo,
        sailingData,
      };

      const bookNowRes = await bookNow(buildUpdateReservationBody(bookNowPaymentData, { sailorParams: sailorObj }));
      const showAddress = sailorObj?.showAddress;
      const dataObj = [
        {
          ...bookNowRes[0],
          sailorCount: parsedParameters?.sailors || 2,
          sailorDetails: bookNowRes[0]?.sailorDetails.map((sailor) => ({
            ...sailor,
            hasOptedForContactForFlights: sailorObj?.add_flights || false,
            hasOptedForTripInsurance: showAddress,
          })),
        },
      ];
      dispatch({
        payload: dataObj,
        type: SAILOR_DETAILS_BOOK_NOW_SUCCESS,
      });
      dispatch(sailorFormUpdateAction(false));
    } catch (err) {
      dispatch({
        payload: [],
        type: SAILOR_DETAILS_BOOK_NOW_ERROR,
      });
      dispatch(sailorFormUpdateAction(false));
      console.log('sailor details book now error:::::::::::::::::::::::::::::', err);
    }
  };

export default updateReservationForSailorDetailsAction;
