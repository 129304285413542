import { routes } from '@/ducks/routes';

import { clearInvoiceDataAction } from '../../summary/calculateInvoice';

export const backToChooseCabinAction = () => (dispatch) => {
  dispatch(clearInvoiceDataAction());
  routes.planner.chooseCabin.go({ keepSearch: true });
};

export default backToChooseCabinAction;
