import { SAILOR_FORM_RESET, SAILOR_FORM_UPDATED } from '@/constants/actionTypes';

export const sailorFormUpdateAction = (isSailorFormUpdated) => async (dispatch) => {
  try {
    const eventType = isSailorFormUpdated ? SAILOR_FORM_UPDATED : SAILOR_FORM_RESET;
    dispatch({
      type: eventType,
    });
  } catch (err) {
    dispatch({
      type: SAILOR_FORM_RESET,
    });
  }
};

export default sailorFormUpdateAction;
