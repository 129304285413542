import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { generateCabinNumber } from '@/helpers/selectors';
import { getBookingSource } from '@/helpers/util/misc';

export default (dashboardData, guestSailor) => {
  const cabinNumber = get(dashboardData, '[0].cabins[0].cabinNumber', '');
  const cabinSide = get(dashboardData, '[0].cabins[0].cabinSide', '');
  const deck = get(dashboardData, '[0].cabins[0].deck', '');
  const reservationNumber = get(dashboardData, '[0].reservationNumber', '');
  const shipCode = get(dashboardData, '[0].shipCode', '');
  const voyageId = get(dashboardData, '[0].voyageId', '');
  const generatedCabinNumber = generateCabinNumber(deck, cabinNumber, cabinSide);
  const accessible = get(dashboardData, '[0].cabins[0].accessible', false);
  const hasOptedForTripInsurance = get(dashboardData, '[0].sailorDetails[0].hasOptedForTripInsurance', false);
  const hasOptedForContactForFlights = get(dashboardData, '[0].sailorDetails[0].hasOptedForContactForFlights', false);
  const booknow = {};
  // reservation Number
  booknow.bookingNumber = reservationNumber;

  // voyage Id
  booknow.voyageId = voyageId;

  // ship code
  booknow.shipCode = shipCode;

  // cabin category
  const cabinType = get(dashboardData, '[0].cabins[0].cabinCategoryCode');

  // guest count
  const guestCount = get(dashboardData, '[0].sailorDetails.length', 1);
  const {
    city,
    countryCode,
    lineOne,
    lineTwo,
    state, // for now we are extracting state also as we are getting state in dashboard call
    stateCode,
    zipCode,
  } = get(dashboardData, '[0].sailorDetails[0].address', {});

  // sailor obj
  const sailors = [];
  const stateValue = !isEmpty(state) ? state : undefined;

  guestSailor.forEach((gSailor, index) => {
    if (!hasOptedForTripInsurance) {
      sailors.push({
        dateOfBirth: gSailor.dob,
        givenName: gSailor.firstname,
        guestRefNumber: JSON.stringify(index + 2),
        hasOptedForContactForFlights,
        hasOptedForTripInsurance,
        lastName: gSailor.lastname,
      });
    } else if (gSailor.hasPrimaryAddressValue === false) {
      sailors.push({
        address: {
          city: !isEmpty(gSailor.city) ? gSailor.city : undefined,
          countryCode: gSailor.country,
          lineOne: gSailor.streetAddress,
          lineTwo: !isEmpty(gSailor.aptNo) ? gSailor.aptNo : undefined,
          stateCode: !isEmpty(gSailor.stateCode) ? gSailor.stateCode : undefined,
          zipCode: gSailor.postalCode,
        },
        dateOfBirth: gSailor.dob,
        givenName: gSailor.firstname,
        guestRefNumber: JSON.stringify(index + 2),
        hasOptedForContactForFlights,
        hasOptedForTripInsurance,
        lastName: gSailor.lastname,
      });
    } else {
      sailors.push({
        address: {
          city: !isEmpty(city) ? city : undefined,
          countryCode,
          lineOne,
          lineTwo: !isEmpty(lineTwo) ? lineTwo : undefined,
          stateCode: isEmpty(stateCode) ? stateValue : stateCode,
          zipCode,
        },
        dateOfBirth: gSailor.dob,
        givenName: gSailor.firstname,
        guestRefNumber: JSON.stringify(index + 2),
        hasOptedForContactForFlights,
        hasOptedForTripInsurance,
        lastName: gSailor.lastname,
      });
    }
  });

  const promoCode = get(dashboardData, '0.promoCode', '');
  const accessKeys = get(dashboardData, '0.accessKeys', []);

  const cabins = [];
  if (cabinType) {
    cabins.push({
      accessKeys,
      accessible,
      cabinCategoryCode: cabinType,
      cabinNumber: generatedCabinNumber,
      guestCount,
      promoCode: promoCode || '',
      sailors,
    });
  }
  booknow.cabins = cabins;

  // currencyCode
  const currencyCode = get(dashboardData, '[0].paymentDetails[0].currencyCode', 'USD');
  return { ...booknow, ...getBookingSource(currencyCode, false, false) };
};
