import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';

export const getCabinInvoiceNumber = (calculateInvoice) => {
  if (isEmpty(calculateInvoice)) {
    return;
  }
  const invoiceData = calculateInvoice?.cabinInvoices[0];
  const { cabinNumber = '', cabinSide = '', deck = '' } = invoiceData;
  return deck + cabinNumber + cabinSide;
};

export const getCabinNumber = (state) => {
  const calculateInvoice = get(state, 'voyagePlanner.summary.calculateInvoice.data', {});
  const dashboardData = get(state, 'accounts.dashboard.data', {});
  if (isEmpty(calculateInvoice) && isEmpty(dashboardData)) {
    return;
  }
  if (!isEmpty(get(state, 'voyagePlanner.summary.calculateInvoice.data'))) {
    return (
      get(state, 'voyagePlanner.summary.calculateInvoice.data.cabinInvoices[0].deck', '') +
      get(state, 'voyagePlanner.summary.calculateInvoice.data.cabinInvoices[0].cabinNumber', '') +
      get(state, 'voyagePlanner.summary.calculateInvoice.data.cabinInvoices[0].cabinSide', '')
    );
  }
  return (
    get(state, 'accounts.dashboard.data.0.cabins.0.deck', '') +
    get(state, 'accounts.dashboard.data.0.cabins.0.cabinNumber', '') +
    get(state, 'accounts.dashboard.data.0.cabins.0.cabinSide', '')
  );
};

export const getSummaryPrice = (cabinInvoices = [], primaryInsurance, isPayInFullPromoApplied) =>
  reduce(
    cabinInvoices,
    (sum, cabin) => {
      const lookupObject = get(cabin, isPayInFullPromoApplied ? 'fullPayPrice' : 'bookingPriceDetails', {
        amount: 0,
        tripInsurance: 0,
      });

      let summaryPrice = sum + lookupObject.amount;
      if (!primaryInsurance) {
        summaryPrice -= lookupObject.tripInsurance;
      }
      return summaryPrice;
    },
    0,
  );

export const generateCabinNumber = (deck, cabinNumber, cabinSide) => `${deck}${cabinNumber}${cabinSide}`;
