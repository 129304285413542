import type { PromoCode } from '@/ducks/accessKeys/type';
import type { FetchCabinsRequest } from '@/ducks/cabinsCommon/types';
import type { TCurrencyCode } from '@/infra/types/common';

import { selectAccessKeyPromoCode } from '@/ducks/accessKeys';
import { selectBookingSourcePayload } from '@/ducks/api/selectors';
import { selectAccessible, selectCurrencyCode, selectSailors } from '@/ducks/filters/selectors';
import createMemoSelector from '@/helpers/createMemoSelector';

export const selectPayloadForCabinsFetch = createMemoSelector(
  [selectAccessible, selectCurrencyCode, selectSailors, selectBookingSourcePayload, selectAccessKeyPromoCode],
  (
    isAccessible: boolean,
    currencyCode: TCurrencyCode,
    sailors: number,
    bookingSource: ReturnType<typeof selectBookingSourcePayload>,
    promoCode?: PromoCode,
  ) => {
    const searchQualifier: FetchCabinsRequest['searchQualifier'] = {
      accessible: isAccessible,
      cabins: [
        {
          guestCounts: [
            {
              ageCategory: 'Adult',
              count: sailors,
            },
          ],
        },
      ],
      currencyCode,
      promoCodes: promoCode ? [promoCode] : [],
    };

    return {
      searchQualifier,
      ...bookingSource,
    };
  },
);
