import { SET_PAY_IN_FULL_PROMO_APPLIED_FLAG } from '@/constants/actionTypes';

export const payInFullPromoAppliedAction = (payInFullPromoFlag) => async (dispatch) => {
  dispatch({
    payload: payInFullPromoFlag,
    type: SET_PAY_IN_FULL_PROMO_APPLIED_FLAG,
  });
};

export default payInFullPromoAppliedAction;
