import { createAsyncThunk } from '@reduxjs/toolkit';

import { throwError } from '@/ducks/common';
import { userProfile } from '@/features/api/endpoints/user';
import { getAccessTokenFromStorage } from '@/helpers/auth/core';
import sharedThunk from '@/helpers/sharedThunk';
import { type TUserProfile } from '@/infra/types/user';
import { type TOptional } from '@/types/common';

import { THUNK_PREFIXES } from './types';

export const fetchUserProfile = sharedThunk(
  createAsyncThunk<TOptional<TUserProfile>>(THUNK_PREFIXES.fetchUserProfile, async (_, { dispatch }) => {
    const token = getAccessTokenFromStorage();
    try {
      return await userProfile(token!);
    } catch (err) {
      dispatch(throwError(err as Error));
      throw err;
    }
  }),
);
