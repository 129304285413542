import { VOYAGE_SEARCH_VOYAGE_EXHAUST_ERROR_SET } from '@/constants/actionTypes';

export const voyageSearchVoyageExhaustErrorSetAction =
  (isError = false) =>
  async (dispatch) => {
    dispatch({
      payload: isError,
      type: VOYAGE_SEARCH_VOYAGE_EXHAUST_ERROR_SET,
    });
  };

export default voyageSearchVoyageExhaustErrorSetAction;
