import {
  CLEAR_FUTURE_VOYAGE_CREDITS_DETAILS,
  FETCH_FUTURE_VOYAGE_CREDITS_DETAILS_ERROR,
  FETCH_FUTURE_VOYAGE_CREDITS_DETAILS_SUCCESS,
} from '@/constants/actionTypes';
import makeApolloClient from '@/features/apollo/makeApolloClient';
import { FETCH_VOYAGE_CREDITS } from '@/helpers/api/graphql/queries/voyage-credits';
import { AppAuthenticator } from '@/helpers/api/tokens';

const apolloClient = makeApolloClient();

export const fetchFVCDetailsAction = (params) => async (dispatch) => {
  const { clientId, currency, voyageStartDate } = params;

  let fallback;
  try {
    const tokens = await AppAuthenticator.getInstance().getAllFreshTokens();
    const guestAccessToken = tokens.guest?.access_token;

    const answer = await apolloClient.query({
      context: { headers: { authorization: `bearer ${guestAccessToken}` } },
      query: FETCH_VOYAGE_CREDITS,
      variables: {
        clientId,
        currency,
        voyageStartDate,
      },
    });
    const { data, error } = answer || {};
    if (!error) {
      const payload = data?.voyageCredits || [];
      dispatch({ payload, type: FETCH_FUTURE_VOYAGE_CREDITS_DETAILS_SUCCESS });
      return payload;
    }
    fallback = error.message || `${error}`;
  } catch (err) {
    fallback = err.message || `${err}`;
  }
  dispatch({ payload: fallback, type: FETCH_FUTURE_VOYAGE_CREDITS_DETAILS_ERROR });
  return [];
};

export const clearFVCDetails = () => async (dispatch) => {
  dispatch({
    payload: {},
    type: CLEAR_FUTURE_VOYAGE_CREDITS_DETAILS,
  });
};
