import { type AsyncThunk, type Dispatch } from '@reduxjs/toolkit';
import {
  type AsyncThunkFulfilledActionCreator,
  type AsyncThunkRejectedActionCreator,
} from '@reduxjs/toolkit/src/createAsyncThunk';

import { type AppDispatch } from '@/store';
import { type TOptional } from '@/types/common';

type AsyncThunkConfig = { dispatch?: Dispatch } & Record<string, unknown>;

// TODO: This is a simple implementation without support of any thunk arguments

const sharedThunk = <R, A, C extends AsyncThunkConfig>(thunk: AsyncThunk<R, A, C>) => {
  type V = ReturnType<AsyncThunkFulfilledActionCreator<R, A>> | ReturnType<AsyncThunkRejectedActionCreator<A, C>>;
  let promise: TOptional<Promise<V>>;
  const action = () => (dispatch: AppDispatch) => {
    if (!promise) {
      promise = dispatch(thunk()).then((result) => {
        promise = undefined;
        return result;
      });
    }
    return promise!;
  };
  const { fulfilled, pending, rejected, typePrefix } = thunk;
  Object.assign(action, { fulfilled, pending, rejected, typePrefix });
  return action as unknown as AsyncThunk<R, A, C>;
};

export default sharedThunk;
