import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import { BOOK_NOW_ENCRYPTED_RESERVATION_SUCCESS, SAILOR_DETAILS_BOOK_NOW_SUCCESS } from '@/constants/actionTypes';
import { getAccessKey, removeActiveAccessKeyAction } from '@/ducks/accessKeys';
import { selectMnvvReservationNumber } from '@/ducks/mnvv/selectors';
import {
  generateReferralUrlActionForCelebrations,
  linkCelebrationsAction,
} from '@/ducks/programs/celebrations/actions';
import { generateReferralUrlAction, linkReferredBookerAction } from '@/ducks/programs/mgm/actions';
import { routes } from '@/ducks/routes';
import { bookNow as safeBookNow } from '@/features/api/safeCalls/payment';
import buildGenerateReservationBody from '@/helpers/api/mappers/req/bookNow/buildGenerateReservationBody';
import { setPaymentReservationNumber } from '@/helpers/managers/payment';
import { getCabinNumber } from '@/helpers/selectors';
import { getSessionStorageValue, setSessionStorageValue } from '@/helpers/util';
import { isVWOFeatureEnabled } from '@/helpers/vwo/isFeatureEnabled';
import { VWOFlag } from '@/types/vwo';

export const bookNowAction = (args) => async (dispatch, getState) => {
  const { holdBooking, lookup, parsedParameters, payInFullPromo, sailingData, sailorDetails } = args;

  const state = getState();
  const celebrations = get(state, 'common.settings.featureFlags.celebrations', false);
  const cabinNoInsession = getSessionStorageValue('cabinNumber');
  const cabinNumber = getCabinNumber(state);
  if (isEmpty(cabinNoInsession) && cabinNumber) {
    setSessionStorageValue('cabinNumber', cabinNumber);
  }

  const accessKeyDetails = getAccessKey(state);
  if (accessKeyDetails?.accessKey && accessKeyDetails?.promoCode) {
    parsedParameters.accessKey = accessKeyDetails.accessKey;
    parsedParameters.promoCode = accessKeyDetails.promoCode;
  }
  if (isEmpty(parsedParameters.shipCode)) {
    parsedParameters.shipCode = sailingData?.ship?.code;
  }
  if (!isEmpty(parsedParameters)) {
    if (isNil(sailorDetails)) {
      return null;
    }

    let data = [];
    const isNewHoldLoginEnabled = await isVWOFeatureEnabled(VWOFlag.BOOK_NOW);

    const mnvvReservationNumber = selectMnvvReservationNumber(state);

    const sailorObj = {
      ...sailorDetails,
      cabinNumber: cabinNumber || cabinNoInsession,
      holdBooking,
      lookup,
      mnvvReservationNumber,
      parsedParameters,
      payInFullPromo,
    };

    if (isNewHoldLoginEnabled) {
      sailorObj.holdBooking = holdBooking;
    }

    data = await safeBookNow(buildGenerateReservationBody(sailorObj));
    if (data.isCaught) return;

    const reservationNumber = get(data, '[0].reservationNumber');
    if (isEmpty(reservationNumber)) {
      routes.page404.go();
    }
    setPaymentReservationNumber(reservationNumber);
    setSessionStorageValue('encResId', data[0].encResId);

    const dataObj = get(sailorObj, 'showAddress', false)
      ? [
          {
            ...data[0],
            sailorCount: get(parsedParameters, 'sailors', 2),
            sailorDetails: data[0].sailorDetails.map((sailor) => ({
              ...sailor,
              hasOptedForContactForFlights: get(sailorObj, 'add_flights', false),
              hasOptedForTripInsurance: true,
            })),
          },
        ]
      : [
          {
            ...data[0],
            sailorCount: get(parsedParameters, 'sailors', 2),
            sailorDetails: data[0].sailorDetails.map((sailor) => ({
              ...sailor,
              hasOptedForContactForFlights: get(sailorObj, 'add_flights', false),
              hasOptedForTripInsurance: false,
            })),
          },
        ];
    data = dataObj;
    dispatch({
      payload: data,
      type: SAILOR_DETAILS_BOOK_NOW_SUCCESS,
    });
    dispatch({
      payload: get(data, '0.encResId', ''),
      type: BOOK_NOW_ENCRYPTED_RESERVATION_SUCCESS,
    });
    dispatch(removeActiveAccessKeyAction());
    const { packageCode, shipCode, voyageId } = parsedParameters;

    if (celebrations) {
      const { newReferral, referralCode } = state.celebrations.params;
      if (referralCode !== null && newReferral === false) {
        dispatch(
          linkCelebrationsAction({
            packageCode,
            referralCode,
            reservationNumber,
            shipCode,
            voyageId,
          }),
        );
      } else {
        dispatch(
          generateReferralUrlActionForCelebrations({
            isCelebrations: celebrations,
            packageCode,
            reservationNumber,
            shipCode,
            voyageId,
          }),
        );
      }
    } else if (state.mgm.isMgm) {
      // Member Get Member: get referral code and url by reservation number
      // link referred booker with original booker if needed
      dispatch(
        generateReferralUrlAction({
          packageCode,
          reservationNumber,
          shipCode,
          voyageId,
        }),
      );

      if (state.mgm.isReferredBooker) {
        dispatch(linkReferredBookerAction(reservationNumber));
      }
    }

    return data;
  }
  return null;
};

export default bookNowAction;
