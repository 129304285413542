import { HttpError } from '@/helpers/api/utils';

export const extractErrorMessage = (err /* :unknown */) /* :string */ => {
  if (err instanceof HttpError) {
    return err.responseBody?.message || err.message;
  }
  if (err instanceof Error) {
    return err.message || `${err}`;
  }
  return err?.message;
};
