import { SET_TRAVEL_INSURANCE_DATA_FAILURE, SET_TRAVEL_INSURANCE_DATA_SUCCESS } from '@/constants/actionTypes';
import { getFlyoutDataAction } from '@/ducks/flyout/actions';
import { fetchTravelInsuranceFlyoutData } from '@/helpers/api/app';

export const fetchTravelInsuranceFlyoutDataAction = () => async (dispatch) => {
  dispatch(
    getFlyoutDataAction(
      fetchTravelInsuranceFlyoutData(),
      SET_TRAVEL_INSURANCE_DATA_SUCCESS,
      SET_TRAVEL_INSURANCE_DATA_FAILURE,
    ),
  );
};

export default fetchTravelInsuranceFlyoutDataAction;
