import type { TCabinUnholdApiArgs } from '@/infra/types/cabin';
import type { AppDispatch } from '@/store';
import type { TOptional } from '@/types/common';

import { SUMMARY_UNHOLD_CABIN_ERROR, SUMMARY_UNHOLD_CABIN_SUCCESS } from '@/constants/actionTypes';
import { cabinUnhold } from '@/helpers/api/app';

import { cabinHoldSetIsCanceling } from '../slice';

export const cabinUnholdAction =
  (cabinDetails: TCabinUnholdApiArgs) =>
  async (dispatch: AppDispatch): Promise<TOptional<string[]>> => {
    try {
      if (cabinDetails) {
        dispatch(cabinHoldSetIsCanceling());
        const data: string[] = await cabinUnhold(cabinDetails);
        dispatch({
          payload: data,
          type: SUMMARY_UNHOLD_CABIN_SUCCESS,
        });
        return data;
      }
    } catch (err) {
      dispatch({
        payload: null,
        type: SUMMARY_UNHOLD_CABIN_ERROR,
      });
    }
  };
