import { CLEAR_FLYOUT_DATA, GET_FLYOUT_DATA, GET_FLYOUT_DATA_FAILURE, GET_FLYOUT_DATA_SUCCESS } from './actionTypes';

/**
 * @param fetchFlyoutData - function - data fetching function for the 'Flayout'
 * @param additionalSuccessAction - string - an additional action to be processed if 'fetchFlyoutData' is successful
 * @param additionalFailureAction - string - an additional action to be processed if 'fetchFlyoutData' fails
 */

export const getFlyoutDataAction =
  (fetchFlyoutData, additionalSuccessAction, additionalFailureAction) => async (dispatch) => {
    let data = {};
    dispatch({ type: GET_FLYOUT_DATA });

    try {
      data = await fetchFlyoutData;
      dispatch({
        payload: data,
        type: GET_FLYOUT_DATA_SUCCESS,
      });
      if (additionalSuccessAction) {
        dispatch({
          payload: data,
          type: additionalSuccessAction,
        });
      }
    } catch (err) {
      dispatch({
        payload: null,
        type: GET_FLYOUT_DATA_FAILURE,
      });
      if (additionalFailureAction) {
        dispatch({
          payload: data,
          type: additionalFailureAction,
        });
      }
    }

    return data;
  };

export const clearFlyoutDataAction = () => async (dispatch) => {
  dispatch({ type: CLEAR_FLYOUT_DATA });
};
